/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
// gatsby-browser.js
export const onClientEntry = () => {
    (function(h, i, v, e, s, d, k){
      h.HiveSDKObject = s; 
      h[s] = h[s] || function(){
        // eslint-disable-next-line no-sequences
        (h[s].q = h[s].q || []).push(arguments)
      }, d = i.createElement(v), k = i.getElementsByTagName(v)[0];
      d.async = 1; d.id = s; d.src = e + '?sef=1&r=' + parseInt(new Date() / 60000);
      k.parentNode.insertBefore(d, k);
    })(window, document, 'script', 'https://cdn-prod.hive.co/static/js/sdk-loader.js', 'HIVE_SDK');
  
    window.onload = () => {
      if (window.HIVE_SDK && typeof window.HIVE_SDK === 'function') {
        window.HIVE_SDK('init', 132005);
      }
    };
  }
  